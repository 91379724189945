import { useAdminContext } from "../admin/context/AdminContext";
import React, { createContext, useContext, useState } from "react";
import Service from "../../api/service";
import { _TYPES_ } from "../../pages/doseeData/utils/types";

const DoSeeDataContext = createContext();
export const useDoSeeDataContext = () => useContext(DoSeeDataContext);

export const DoSeeDataProvider = (props) => {
  const [events, setEvents] = useState(null);
  const [attractions, setAttractions] = useState(null);
  const [cinema, setCinema] = useState(null);
  const [music, setMusic] = useState(null);
  const [news, setNews] = useState(null);
  const [safari, setSafari] = useState(null);
  const [bestOfAfrica, setBestOfAfrica] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [thingsToDos, setThinsToDo] = useState(null);
  const [parties, setParty] = useState(null);
  const [nightlifes, setNightlife] = useState(null);
  const [cityGuides, setCityGuide] = useState(null);
  const [hotels, setHotel] = useState(null);
  const [classes, setClasses] = useState(null);
  const [shoppings, setShopping] = useState(null);
  const [foods, setFood] = useState(null);
  const [books, setBook] = useState(null);
  const [neighborhoods, setNeighborhood] = useState(null);
  const [ministries, setMinistry] = useState(null);
  const [companies, setCompany] = useState(null);
  const [embassies, setEmbassy] = useState(null);
  const [gettingArounds, setGettingAround] = useState(null);
  const [arts, setArt] = useState(null);
  const [movies, setMovie] = useState(null);
  const [dances, setDance] = useState(null);
  const [theaters, setTheater] = useState(null);
  const [tipsAndAdvice, setTipsAndAdvice] = useState(null);
  const [museums, setMuseum] = useState(null);
  const [advertisements, setAdvertisement] = useState(null);
  const [products, setProducts] = useState(null);
  const [tourPackages, setTourPackage] = useState(null);

  const [eventsCount, setEventsCount] = useState(null);
  const [attractionsCount, setAttractionsCount] = useState(null);
  const [cinemaCount, setCinemaCount] = useState(null);
  const [musicCount, setMusicCount] = useState(null);
  const [newsCount, setNewsCount] = useState(null);
  const [safariCount, setSafariCount] = useState(null);
  const [bestOfAfricaCount, setBestOfAfricaCount] = useState(null);
  const [restaurantCount, setRestaurantCount] = useState(null);
  const [thingsToDosCount, setThinsToDoCount] = useState(null);
  const [partiesCount, setPartyCount] = useState(null);
  const [nightlifesCount, setNightlifeCount] = useState(null);
  const [cityGuidesCount, setCityGuideCount] = useState(null);
  const [hotelsCount, setHotelCount] = useState(null);
  const [classesCount, setClassesCount] = useState(null);
  const [shoppingsCount, setShoppingCount] = useState(null);
  const [foodsCount, setFoodCount] = useState(null);
  const [booksCount, setBookCount] = useState(null);
  const [neighborhoodsCount, setNeighborhoodCount] = useState(null);
  const [ministriesCount, setMinistryCount] = useState(null);
  const [companiesCount, setCompanyCount] = useState(null);
  const [embassiesCount, setEmbassyCount] = useState(null);
  const [gettingAroundsCount, setGettingAroundCount] = useState(null);
  const [artsCount, setArtCount] = useState(null);
  const [moviesCount, setMovieCount] = useState(null);
  const [dancesCount, setDanceCount] = useState(null);
  const [theatersCount, setTheaterCount] = useState(null);
  const [tipsAndAdviceCount, setTipsAndAdviceCount] = useState(null);
  const [museumsCount, setMuseumCount] = useState(null);
  const [advertisementCount, setAdvertisementCount] = useState(null);
  const [productsCount, setProductsCount] = useState(null);
  const [tourPackagesCount, setTourPackageCount] = useState(null);

  const { sysAdmin, singleAdmin } = useAdminContext();
  const token = String(sysAdmin?.token);

  const getPositionKeys = ({
    country_id,
    first_level_id,
    third_level_id,
    second_level_id,
    fourth_level_id,
  }) => {
    const obj = {};
    if (country_id) obj.country_id = country_id;
    if (first_level_id) obj.first_level_id = first_level_id;
    if (second_level_id) obj.second_level_id = second_level_id;
    if (third_level_id) obj.third_level_id = third_level_id;
    if (fourth_level_id) obj.fourth_level_id = fourth_level_id;
    return obj;
  };

  /**
   * Fetch all events by either
   * country id / first level id / second level id / third level id / fourth level id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchDoSeeData = async (
    {
      country_id,
      first_level_id,
      second_level_id,
      third_level_id,
      fourth_level_id,
      content,
    },
    page = 1,
    pageSize = 50
  ) => {
    // When trying to get data entered by an admin in a period: POST METHOD
    const { ids, type } = singleAdmin;
    const year = singleAdmin?.year;
    const month = singleAdmin?.month;
    const noLocation = singleAdmin?.noLocation;

    const URL = `/api/v1/stats/data/content/?page=${page}&pageSize=${pageSize}`;

    // TO BE DONE
    // make sure we differentiate when we want to fetch by location or simply everyting with no location in mind
    // If we want all user data, we don't need to send the location otherwise
    // It will filter based on the location
    // noLocation is coming rom View 'Admin Stats' Component
    const positionKeys =
      country_id === 0 || noLocation === true
        ? {}
        : getPositionKeys({
            country_id,
            first_level_id,
            second_level_id,
            third_level_id,
            fourth_level_id,
          });

    const localization =
      month && year
        ? { ids, year, month, type, ...positionKeys }
        : { ids, type, ...positionKeys };

    await Service.post(URL, localization, token).then(
      ({ data: { rows: data, count } }) => {
        // console.log(data, count);
        setDataState({ data, count, content });
      }
    );
  };

  /**
   * Create one content
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneContent = async ({ data, content, position }) => {
    const table = getRoute(content);
    const URL = `/api/v1/admin/${table}/create`;
    const { message, status } = await Service.post(URL, data, token);
    if (status === 200) await fetchDoSeeData({ content, ...position }, 1, 50);
    return { message, status };
  };

  /**
   * Delete an content
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteOneContent = async ({ data_id, content }) => {
    const table = getRoute(content);
    const URL = `/api/v1/admin/${table}/delete/${data_id}`;
    const { message, status } = await Service.delete(URL, token);
    return { message, status };
  };

  /**
   * Update a content
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateOneContent = async ({ data_id, data, content, position }) => {
    const table = getRoute(content);
    const URL = `/api/v1/admin/${table}/update/${data_id}`;
    const { message, status } = await Service.put(URL, data, token);
    if (status === 200 && position) {
      await fetchDoSeeData({ content, ...position }, 1, 50);
    }
    return { message, status };
  };

  /**
   * Get Data Routes
   *
   * @author Ntavigwa Bashombe
   * @returns string
   */
  const getRoute = (content) => {
    if (content === _TYPES_.EVENT) return "event";
    else if (content === _TYPES_.NEWS) return "news";
    else if (content === _TYPES_.MUSIC) return "music";
    else if (content === _TYPES_.SAFARI) return "safari";
    else if (content === _TYPES_.CINEMA) return "cinema";
    else if (content === _TYPES_.ATTRACTION) return "attraction";
    else if (content === _TYPES_.RESTAURANT) return "restaurant";
    else if (content === _TYPES_.BESTOFAFRICA) return "best/africa";
    else if (content === _TYPES_.THINGSTODO) return "things/todo";
    else if (content === _TYPES_.PARTY) return "party";
    else if (content === _TYPES_.NIGHTLIFE) return "nightlife";
    else if (content === _TYPES_.CITYGUIDE) return "city/guide";
    else if (content === _TYPES_.HOTEL) return "hotel";
    else if (content === _TYPES_.CLASSES) return "class";
    else if (content === _TYPES_.SHOPPING) return "shopping";
    else if (content === _TYPES_.FOOD) return "food";
    else if (content === _TYPES_.BOOK) return "book";
    else if (content === _TYPES_.NEIGHBORHOOD) return "neighborhood";
    else if (content === _TYPES_.MINISTRY) return "ministry";
    else if (content === _TYPES_.GETTINGAROUND) return "getting/around";
    else if (content === _TYPES_.ART) return "art";
    else if (content === _TYPES_.MOVIE) return "movie";
    else if (content === _TYPES_.DANCE) return "dance";
    else if (content === _TYPES_.THEATER) return "theater";
    else if (content === _TYPES_.TIPSANDADVICE) return "tips/advice";
    else if (content === _TYPES_.MUSEUM) return "museum";
    else if (content === _TYPES_.ADV) return "sponsorship";
    else if (content === _TYPES_.TOURPACKAGE) return "tour/package";
    else if (content === _TYPES_.PRODUCT) return "product";
    else if (content === _TYPES_.COMPANY) return "companies";
    else if (content === _TYPES_.EMBASSY) return "embassy";
    else return "event";
  };

  /**
   * set Data States
   *
   * @author Ntavigwa Bashombe
   * @returns {}
   */
  const setDataState = ({ data, content, count }) => {
    if (content === _TYPES_.EVENT) {
      setEvents(data);
      setEventsCount(count);
    } else if (content === _TYPES_.NEWS) {
      setNews(data);
      setNewsCount(count);
    } else if (content === _TYPES_.MUSIC) {
      setMusic(data);
      setMusicCount(count);
    } else if (content === _TYPES_.CINEMA) {
      setCinema(data);
      setCinemaCount(count);
    } else if (content === _TYPES_.SAFARI) {
      setSafari(data);
      setSafariCount(count);
    } else if (content === _TYPES_.ATTRACTION) {
      setAttractions(data);
      setAttractionsCount(count);
    } else if (content === _TYPES_.RESTAURANT) {
      setRestaurant(data);
      setRestaurantCount(count);
    } else if (content === _TYPES_.BESTOFAFRICA) {
      setBestOfAfrica(data);
      setBestOfAfricaCount(count);
    } else if (content === _TYPES_.THINGSTODO) {
      setThinsToDo(data);
      setThinsToDoCount(count);
    } else if (content === _TYPES_.PARTY) {
      setParty(data);
      setPartyCount(count);
    } else if (content === _TYPES_.NIGHTLIFE) {
      setNightlife(data);
      setNightlifeCount(count);
    } else if (content === _TYPES_.CITYGUIDE) {
      setCityGuide(data);
      setCityGuideCount(count);
    } else if (content === _TYPES_.HOTEL) {
      setHotel(data);
      setHotelCount(count);
    } else if (content === _TYPES_.CLASSES) {
      setClasses(data);
      setClassesCount(count);
    } else if (content === _TYPES_.SHOPPING) {
      setShopping(data);
      setShoppingCount(count);
    } else if (content === _TYPES_.FOOD) {
      setFood(data);
      setFoodCount(count);
    } else if (content === _TYPES_.BOOK) {
      setBook(data);
      setBookCount(count);
    } else if (content === _TYPES_.NEIGHBORHOOD) {
      setNeighborhood(data);
      setNeighborhoodCount(count);
    } else if (content === _TYPES_.MINISTRY) {
      setMinistry(data);
      setMinistryCount(count);
    } else if (content === _TYPES_.GETTINGAROUND) {
      setGettingAround(data);
      setGettingAroundCount(count);
    } else if (content === _TYPES_.ART) {
      setArt(data);
      setArtCount(count);
    } else if (content === _TYPES_.MOVIE) {
      setMovie(data);
      setMovieCount(count);
    } else if (content === _TYPES_.DANCE) {
      setDance(data);
      setDanceCount(count);
    } else if (content === _TYPES_.THEATER) {
      setTheater(data);
      setTheaterCount(count);
    } else if (content === _TYPES_.TIPSANDADVICE) {
      setTipsAndAdvice(data);
      setTipsAndAdviceCount(count);
    } else if (content === _TYPES_.MUSEUM) {
      setMuseum(data);
      setMuseumCount(count);
    } else if (content === _TYPES_.ADV) {
      setAdvertisement(data);
      setAdvertisementCount(count);
    } else if (content === _TYPES_.PRODUCT) {
      setProducts(data);
      setProductsCount(count);
    } else if (content === _TYPES_.TOURPACKAGE) {
      setTourPackage(data);
      setTourPackageCount(count);
    } else if (content === _TYPES_.COMPANY) {
      setCompany(data);
      setCompanyCount(count);
    } else if (content === _TYPES_.EMBASSY) {
      setEmbassy(data);
      setEmbassyCount(count);
    } else {
      setEvents(data);
      setEventsCount(count);
    }
  };

  return (
    <DoSeeDataContext.Provider
      value={{
        news,
        music,
        cinema,
        events,
        safari,
        attractions,
        bestOfAfrica,
        restaurant,
        thingsToDos,
        parties,
        nightlifes,
        cityGuides,
        hotels,
        classes,
        shoppings,
        foods,
        books,
        neighborhoods,
        ministries,
        embassies,
        companies,
        gettingArounds,
        arts,
        movies,
        dances,
        theaters,
        tipsAndAdvice,
        museums,
        advertisements,
        products,
        tourPackages,
        fetchDoSeeData,
        updateOneContent,
        deleteOneContent,
        createOneContent,
        setDataState,
        eventsCount,
        attractionsCount,
        cinemaCount,
        musicCount,
        newsCount,
        safariCount,
        bestOfAfricaCount,
        restaurantCount,
        thingsToDosCount,
        partiesCount,
        nightlifesCount,
        cityGuidesCount,
        hotelsCount,
        classesCount,
        shoppingsCount,
        foodsCount,
        booksCount,
        neighborhoodsCount,
        ministriesCount,
        gettingAroundsCount,
        artsCount,
        moviesCount,
        dancesCount,
        theatersCount,
        tipsAndAdviceCount,
        museumsCount,
        advertisementCount,
        productsCount,
        tourPackagesCount,
        embassiesCount,
        companiesCount,
      }}
    >
      {props.children}
    </DoSeeDataContext.Provider>
  );
};
