/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { MyButton } from "../../component/Component";
import { AiOutlineLoading } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import Service from "../../../../api/service";
import { getContentMasterSearchParams } from ".";
import { SeachContent } from "./helpers";

const Overview = () => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [localSearch, setLocalSearch] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (keyword && keyword !== "") {
      getSearch();
    }
  };

  const getSearch = async () => {
    const url = `/api/v1/search/?search_key=${keyword}&origin=admin`;
    setSearchLoading(true);
    const { data } = await Service.get(url);
    setSearchLoading(false);
    if (data) setLocalSearch(data);
  };

  let totalResult = 0;
  if (localSearch) {
    for (const key in localSearch) {
      if (
        ![
          "Company",
          "FirstLevel",
          "SecondLevel",
          "ThirdLevel",
          "FourthLevel",
        ].includes(key)
      ) {
        totalResult += localSearch[key]?.length;
      }
    }
  }

  return (
    <div className="p-3 lg:p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <div className="w-full h-full flex justify-center items-center">
          <form onSubmit={handleSearch} className="flex">
            <input
              type="text"
              placeholder="Search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              className="w-full rounded-l-md border border-r-0 border-gray-200 p-3 text-xs font-medium outline-none placeholder:text-xs md:w-auto md:min-w-[250px] md:p-4 lg:min-w-[350px]"
            />

            <MyButton
              type="submit"
              onClick={() => {
                setSearchLoading(true);
              }}
              className="flex w-[120px] items-center justify-center space-x-2 rounded-r-md border-2 border-primary bg-primary px-10 text-xs font-medium text-white md:px-4"
            >
              {searchLoading ? (
                <AiOutlineLoading className="animate-spin text-base" />
              ) : (
                <>
                  <FiSearch className="text-lg" />
                  <span>Search</span>
                </>
              )}
            </MyButton>
          </form>
        </div>
        {localSearch ? (
          <div className="pt-5">
            <p className="text-xs text-gray-600">
              About {totalResult} Results Found.
            </p>
          </div>
        ) : null}
        <div className="w-full py-5">
          <div className="h-full w-full pt-2 lg:w-2/3 xl:w-1/2 mx-auto">
            {localSearch ? (
              <div className="flex h-full w-full flex-col space-y-5">
                {getContentMasterSearchParams(localSearch)?.map(
                  (content, key) => (
                    <SeachContent
                      key={key}
                      data={content?.data}
                      route={content?.route}
                      title={content?.title}
                      idLabel={content?.idLabel}
                      setLocalSearch={setLocalSearch}
                      titleLabel={content?.titleLabel}
                      collection={content.collection}
                      pushMethod={content.pushMethod}
                      onDeleteAction={getSearch}
                    />
                  )
                )}
              </div>
            ) : (
              <div className="text-center text-xs font-medium italic">
                Results will show here
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
